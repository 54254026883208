import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {Configuration} from '../Configuration';
import {apiAdmin, apiCohort, apiDemandInfluence, apiDemandDetail, apiUnit, apiRate, apiRebl, apiTables} from "./api";
import adminReducer from '../store/admin';
import cohortReducer from '../store/cohort';
import demandInfluenceReducer from '../store/demandInfluence';
import demandDetailReducer from "../store/demandDetail";
import rateReducer from "../store/rates";
import reblReducer from "../store/rebl";
import tablesReducer from "../store/tables";
import unitReducer from "../store/units";

const rootReducer = combineReducers({
    [apiAdmin.reducerPath]: apiAdmin.reducer,
    [apiCohort.reducerPath]: apiCohort.reducer,
    [apiDemandInfluence.reducerPath]: apiDemandInfluence.reducer,
    [apiDemandDetail.reducerPath]: apiDemandDetail.reducer,
    [apiRebl.reducerPath]: apiRebl.reducer,
    [apiTables.reducerPath]: apiTables.reducer,
    [apiUnit.reducerPath]: apiUnit.reducer,
    [apiRate.reducerPath]: apiRate.reducer,
    "adminList": adminReducer,
    "cohorts": cohortReducer,
    "demandInfluences": demandInfluenceReducer,
    "demandDetails": demandDetailReducer,
    "rebleRulesets": reblReducer,
    "tables": tablesReducer,
    "units": unitReducer,
    "rates": rateReducer,
});

export const store = configureStore({
    devTools: !Configuration.isProduction,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiCohort.middleware]),
});

export type AppState = ReturnType<typeof store.getState>;
