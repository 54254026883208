import React, {useEffect, useState} from 'react';
import {useGetRatesQuery} from '../../store';
import {BulkSidePanel, Loading, RatesEvolutionChart} from '..';
import {BulkUploadOptions, Cohort, Message, Rate, SelectOption} from '../../types';
import {AlertMessage, Button, Icon, Input, withTooltip} from '@vacasa/react-components-lib';
import './RatesEvolution.scss';
import {Helmet} from 'react-helmet';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


interface RatesEvolutionProps {
    cohorts: Cohort[];
}

export const RatesEvolution: React.FC<RatesEvolutionProps> = (props) => {
    const {cohorts} = props;
    // const dispatch = useDispatch();
    // const today = new Date();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [finalRates, setFinalRates] = useState<Rate[]>([]);

    const [ratesInput, setRatesInput] = useState<{unit_id: string, cohort_ids: string}>({unit_id: "", cohort_ids: ""});
    const [unitId, setUnitId] = useState<string>("");
    const [cohortIds, setCohortIds] = useState<string>("");
    const [isShowingSave, setIsShowingSave] = useState(false);

    const cohortOptions: SelectOption[] = [...[{value: "", display: "(unset)"}], ...cohorts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(c => {return {value: c.id.toString(), display: c.name}})]

    // const [startDate, setStartDate] = useState<Date>(today);
    // const [endDate, setEndDate] = useState<Date>(addDays(today, Configuration.DEFAULT_DAYS_RANGE));

    const {
        isFetching: isFetchingRates,
        data: rates,
        refetch: refetchRates
    } = useGetRatesQuery(ratesInput);

    // const [message, setMessage] = useState<Message | null>(null);

    const [uiAlert, setUiAlert] = useState<Message | null>(null);

    const getValue = (multiSelectVariable) => {
        const multiSelectValue = multiSelectVariable?.split(',').map((k: string) => {
            const idx = cohorts.map(c => c.id).indexOf(parseInt(k))
            if (idx >= 0) {
                const match = cohorts[idx]
                return {
                    display: match.name,
                    value: match.id.toString()
                }
            }
            else {
                return {
                    display: k,
                    value: k
                }
            }
        });
        if (!multiSelectVariable || (multiSelectValue?.length === 1 && multiSelectValue[0]?.value === "")) return [];
        return multiSelectValue;
    }

    const handleSave = (message: Message) => {
        setUiAlert(message);
        setIsShowingSave(false);
    }

    const updateRateInputs = (newUnitId: string, newCohortId: string) => {
        setUnitId(newUnitId);
        setCohortIds(newCohortId);
    }

    useEffect(() => {
        if (!isFetchingRates && !!rates) {
            setIsLoading(true);
            let tempFinalRates: Rate[] = [];
            rates.forEach(rate => {
                let rateCopy = {...rate}
                Object.keys(rateCopy).forEach(k => {
                    if (k !== "date") {
                        rateCopy[k] = !!rateCopy[k] ? rateCopy[k] : null
                    }
                })
                tempFinalRates.push(rateCopy)
            })
            setFinalRates(tempFinalRates)
            setIsLoading(false);
        }
    }, [rates, isFetchingRates]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Northstar Rates Viewer</title>
            </Helmet>
            <div>
                {isLoading || isFetchingRates ? <Loading className={"loading"}></Loading> :
                    <>
                    <span style={{float: "right"}}>
                        {withTooltip(
                            'Upload File',
                            <div className="demand-influence-icon" onClick={() => setIsShowingSave(true)}>
                                <Icon.Upload height={24} width={24} id={"upload-icon"}/>
                                <label htmlFor={"upload-icon"} style={{verticalAlign: "super", cursor: "pointer"}}>Upload overrides</label>
                            </div>,
                            {placement: 'top'}
                        )}

                    </span>
                            <div className="chart-container" style={{minHeight: "400px", width: "100%"}}>
                            <RatesEvolutionChart
                            rates={finalRates}
                    />
                </div>
            {uiAlert ? (
                <div className="div-alert">
                    <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small" />
                </div>
            ) : null}
            <div className="rates-input-row">
                <div className="rates-input-group">
                    <label>Unit ID:</label>
                    <Input
                        customClass={"rates-select"}
                        type={"text"}
                        value={unitId}
                        onChange={(e) => updateRateInputs(e.target.value, "")}
                    />
                </div>
                <div className="rates-input-group">
                    <label>Cohorts:</label>
                    <Autocomplete
                        className={"rates-multi-select"}
                        options={
                            cohortOptions.filter(o => {
                                let available = true;
                                cohortIds.split(",").forEach(c => {
                                    if (o.value === c) {
                                        available = false;
                                        return
                                    }
                                });
                                return available;
                            })
                        }
                        getOptionLabel={(option: SelectOption) => option.display}
                        multiple={true}
                        filterSelectedOptions={true}
                        value={getValue(cohortIds)}
                        onChange={(e, values) => {
                            updateRateInputs("", values.map(v => v.value).join(","));
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField {...params} variant="outlined"/>
                            )
                        }}
                    />
                </div>
                <div className="rates-input-group">
                    <Button
                        variant="secondary"
                        onClick={() => setRatesInput({unit_id: unitId, cohort_ids: cohortIds})}
                        disabled={isFetchingRates || (!unitId && !cohortIds)}
                    >
                        Refresh
                    </Button>
                </div>
                {isShowingSave && (
                    <BulkSidePanel
                        onClose={() => setIsShowingSave(false)}
                        uploadType={BulkUploadOptions.OVERRIDES}
                        onSave={handleSave}
                    />
                )}

                </div>
                </>
            }
            </div>

        </React.Fragment>
    );
};
