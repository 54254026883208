import React, {useEffect, useState} from 'react';
import {Paper} from '@material-ui/core';
import {Button, Icon, Modal, Tabs, Tooltip} from '@vacasa/react-components-lib';
import {Configuration} from '../../Configuration';
import './Home.scss';
import { format, fromUnixTime } from 'date-fns';
import {Admin, CohortList, DemandInfluenceList, Loading, ReblRulesetList, UnitList, MissingRates, ReblAdmin} from "../../components";
import {useHistory} from "react-router-dom";
import {Cohort} from "../../types";
import {useGetAllCohortsQuery, useGetCurrentUserQuery, useGetPipelineStatusQuery, useGetUnitLocationOptionsQuery} from "../../store";
import {isEmpty, sortBy} from "lodash";
import {UnitTetherTable} from "../../components/Tables/UnitTether";
import {HolidaysTable} from "../../components/Tables/Holidays";
import {RatesEvolution} from "../../components/Rates/RatesEvolution";

export const Home: React.FC = () => {
    const search = window.location.search;
    const history = useHistory();
    const params = new URLSearchParams(search);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedCohortID, setSelectedCohortID] = useState<string>("");
    const tabParam = params.get('tab');
    const cohortParam = params.get('sc');
    const [openAdminModal, setOpenAdminModal] = useState<boolean>(false);
    const [openMissingRatesModal, setOpenMissingRatesModal] = useState<boolean>(false);
    const [openReblAdminModal, setOpenReblAdminModal] = useState<boolean>(false);
    const [showNonProdWarning, setShowNonProdWarning] = useState<boolean>(true);
    const [pipelineSuccessful, setPipelineSuccessful] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);

    // TODO: cache this
    const {refetch: refetchCohorts, data: cohortInfo, isFetching: isFetchingCohorts} = useGetAllCohortsQuery();
    const {data: currentUser, isFetching: isFetchingCurrentUser} = useGetCurrentUserQuery();
    const {data: locationOptions, isFetching: isFetchingLocationOptions} = useGetUnitLocationOptionsQuery();
    const {data: pipelineStatus, isFetching: isFetchingPipelineStatus, refetch: refetchPipelineStatus} = useGetPipelineStatusQuery();

    const [cohortData, setCohortData] = useState<Cohort[]>([]);

    function onUpdateCohort(updatedCohort: Cohort) {
        const updatedCohorts = cohortData.map(
            cohort => {
                if (cohort.id === updatedCohort.id) {
                    return updatedCohort
                } else {return cohort}
            }
        )
        setCohortData(updatedCohorts)
    }

    const formatDate = (unixTime: number): string => format(fromUnixTime(unixTime), 'yyyy-MM-dd HH:mm:SS');

    useEffect(() => {
        if(!isFetchingPipelineStatus) {
            console.log(pipelineStatus)
            if (
                !!pipelineStatus &&
                pipelineStatus["ns_ui"]["latest_status"] === "success" &&
                pipelineStatus["ns_pipeline"]["latest_status"] === "success"
            ) {
                setPipelineSuccessful(true)
                setStatusMessage(`Data updated ${formatDate(pipelineStatus["ns_ui"]["latest_datetime"])}`)
            }
            else {
                setPipelineSuccessful(false)

                if (!pipelineStatus) {
                    setStatusMessage("Error while fetching pipeline status")
                }
                else {
                    let msg = `NS pipeline running ${pipelineStatus["ns_pipeline"]["latest_task"]} at ${formatDate(pipelineStatus["ns_pipeline"]["latest_datetime"])}`;
                    if (pipelineStatus["ns_pipeline"]["latest_status"] === "running") {
                        setStatusMessage(msg)
                    } else {
                        msg = `Latest pipeline status is ${pipelineStatus["ns_pipeline"]["latest_status"]};` + msg;
                        setStatusMessage(msg)
                    }
                }
            }

            setTimeout(() => {
                setLoadingStatus(false);
            }, 1000) // When users request a refetch the cached response is too immediate, so, make it look like it's doing something
        }
    }, [pipelineStatus, isFetchingPipelineStatus]);

    const updateStatus = () => {
        setLoadingStatus(true);
        if (!isFetchingPipelineStatus) refetchPipelineStatus();
    }

    // setTimeout(() => {refetchPipelineStatus()}, 60_000)

    const tabs = [
        {
            id: "strategic_cohorts",
            label: "Cohorts",
            component: <CohortList
                cohortData={cohortData}
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                onRefresh={refetchCohorts}
                onUpdateCohort={onUpdateCohort}
                currentUser={currentUser}
                refetchCohorts={refetchCohorts}
            />
        },
        {
            id: "demand_influences",
            label: "Demand Influences",
            component: <DemandInfluenceList
                cohortData={cohortData}
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                selectedCohortID={selectedCohortID}
                currentUser={currentUser}
            />
        }, {
            id: "unit_assigner",
            label: "Units",
            component: <UnitList
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                cohortData={cohortData}
                currentUser={currentUser}
            />
        }, {
            id: "rebl",
            label: "REBL",
            component: <ReblRulesetList
                isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                isFetchingLocationOptions={isFetchingLocationOptions}
                cohortData={cohortData}
                currentUser={currentUser}
                locationOptions={locationOptions}
            />
        }, {
            id: "tethering",
            label: "Tethering (WiP)",
            component: <UnitTetherTable/>
        }, {
            id: "holidays",
            label: "Holidays",
            component: <HolidaysTable
                 isFetchingCohorts={isFetchingCohorts || isFetchingCurrentUser}
                 cohortData={cohortData}
                 currentUser={currentUser}
                 locationOptions={locationOptions}
                 isFetchingLocationOptions={isFetchingLocationOptions}
             />
        }, {
            id: "rates",
            label: "Rates Viewer (WiP)",
            component: <RatesEvolution cohorts={cohortData.filter(c => c.unit_count > 0)}/>
        }
    ];

    const [selectedTab, setSelectedTab] = useState<number>(
        Math.max(tabParam ? tabs.findIndex(idx => idx.id === tabParam) : 0, 0)
    );

    const openModal = (name: string) => {
        setOpenAdminModal(name === "admin");
        setOpenMissingRatesModal(name === "missing_rates");
        setOpenReblAdminModal(name === "rebl_admin");
    }

    useEffect(() => {
        if(cohortParam) setSelectedCohortID(cohortParam);
    }, [cohortParam])

    useEffect(() => {
        if (!isEmpty(cohortInfo)) setCohortData(sortBy(cohortInfo, (cohort) => cohort.name));
    }, [cohortInfo, isFetchingCohorts])

    useEffect(() => {
        if (selectedTab !== null && selectedTab >= 0) {
            if (selectedTab !== 1) {
                setSelectedCohortID(null);
            }
            
            let newParams = new URLSearchParams();
            newParams.set("tab", tabs[selectedTab]["id"])
            if(selectedCohortID && selectedTab !== 0) {
                newParams.append("sc", selectedCohortID.toString());
            }

            history.push({
                pathname: window.location.pathname,
                search: "?" + newParams.toString()
            })

            setLoading(false);
        }
    }, [selectedTab, selectedCohortID])

    return (
        <Paper elevation={3} className="home">
            {!Configuration.isProduction && !Configuration.isLocal && showNonProdWarning &&
                <span className={"lower-env-banner"}>
                    <Tooltip message={"hide banner"} onClick={() => setShowNonProdWarning(false)} className={"pointer"}>
                        <Icon.XCircle height={24} width={24}/>
                    </Tooltip>
                    <h1>This is a training environment -- Changes will not be permanent</h1>
                </span>
            }
            <div className="tabs-container">
                { loading ? <Loading></Loading> :
                <>
                    {currentUser?.admin && <div style={{float: "right"}}>
                        <Button variant="secondary" onClick={() => {openModal("admin")}}>
                            Open Admin
                        </Button>
                    </div>
                    }
                    {selectedTab === 2 &&
                        <div style={{float: "right", paddingRight: "5px"}}>
                            <Button variant="primary" onClick={() => {openModal("missing_rates")}}>
                                <Icon.Search height={16} width={16}/>
                                Missing Rates
                            </Button>
                        </div>
                    }
                    {selectedTab === 3 && currentUser?.approver &&
                        <div style={{float: "right", paddingRight: "5px"}}>
                            <Button variant="primary" onClick={() => {openModal("rebl_admin")}}>
                                <Icon.Settings height={16} width={16}/>
                                REBL Adjustments
                            </Button>
                        </div>
                    }
                    <Tabs
                        selected={selectedTab}
                        tabs={tabs}
                        onChange={(index) => setSelectedTab(index)}
                    />
                </>
                }
            </div>
            <Modal
                canExit={true}
                setShowModal={setOpenAdminModal}
                showModal={openAdminModal}
                size='large'
            >
                <Admin></Admin>
            </Modal>
            <Modal
                canExit={true}
                setShowModal={setOpenMissingRatesModal}
                showModal={openMissingRatesModal}
                size='medium'
            >
                <MissingRates/>
            </Modal>
            <Modal
                canExit={true}
                setShowModal={setOpenReblAdminModal}
                showModal={openReblAdminModal}
                size='medium'
            >
                <ReblAdmin/>
            </Modal>
            <footer>
                {loadingStatus ?
                    <>
                        <Icon.Loader className={"spinning-icon"} height={20} width={20}/>
                        Fetching status of Northstar Pipeline...
                    </>
                    :
                    <>
                    {pipelineSuccessful ?
                        <Icon.CheckSquare height={20} width={20}/> :
                        <Icon.AlertTriangle height={20} width={20}/>
                    }
                    {statusMessage} {!isFetchingPipelineStatus && !loadingStatus &&
                        <Icon.RefreshCCW height={16} width={16} onClick={updateStatus}/>
                    }
                    </>
                }
            </footer>
        </Paper>
    );
};